import React from "react";
import { Field, useField } from "formik";
import themeKym from "../../utils/themes/ThemeKym";
import TextField from "@material-ui/core/TextField";

const RucFormatField = ({ props }) => {
  const classesKym = themeKym();
  const [field] = useField(props.name);

  const configTextField = {
    ...field,
    label: props.label,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    minLength: props.minLength,
  };

  configTextField.value = configTextField.value ?? "";

  const mask = (e) => {
    let x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d?)(\d{0,3})(\d{0,3})(\d?)/);

    x = x
      .slice(1)
      .filter((n) => n !== "")
      .join(".");

    if (x.length >= 10) {
      const point = ".";
      const hyphen = "-";

      const pos = x.lastIndexOf(point);
      x = x.substring(0, pos) + hyphen + x.substring(pos + 1);
    }

    e.target.value = x;

    field.onBlur(e);
    field.onChange(e);
  };

  const onlyNumbers = (e) => {
    let x = e.target.value;
    e.target.value = x.replace(/\D/g, "");

    field.onChange(e);
  };

  if (props.material)
    return (
      <TextField
        variant="outlined"
        {...configTextField}
        className={classesKym[`${props.className}`]}
        data-testid={`${props.id}-${props.className}`}
        onChange={(e) => onlyNumbers(e)}
        onBlur={(e) => mask(e)}
        inputProps={{ minLength: props.minLength, maxLength: props.maxLength }}
      />
    );

  return (
    <Field
      {...configTextField}
      onChange={(e) => onlyNumbers(e)}
      onBlur={(e) => mask(e)}
    />
  );
};

export default RucFormatField;
