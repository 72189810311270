import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import { useState, useEffect } from "react";
import themeKym from "../../utils/themes/ThemeKym";
import { Grid } from "@mui/material";
import {
  validateObject,
  existingValueInProperties,
  validateNumber,
} from "../../utils/utils_objects";

export default function ToggleButtonMap({ props, values, setFieldValue }) {
  const [selected, setSelected] = useState(null);
  const classesKym = themeKym();
  const [lastepin, setlastepin] = useState();
  const [lastemoney, setlastemoney] = useState();

  let epin = values.epin_cell_number;
  let emoney = values.emoney_cell_number;
  let label = props.label;
  const [numbers, setnumbers] = useState({ epin: epin, emoney: emoney });

  const listnumbers = () => {
    if (epin !== undefined && epin !== "") {
      setnumbers((prevNumbers) => ({ ...prevNumbers, epin: epin }));
      setlastepin(epin);
    }
    if (emoney !== undefined && emoney !== "") {
      setnumbers((prevNumbers) => ({ ...prevNumbers, emoney: emoney }));
      setlastemoney(emoney);
    }

    let lastnumber = { epin: lastepin, emoney: lastemoney };
    if (!validateObject(lastnumber)) {
      if (
        existingValueInProperties(epin, lastnumber) === false ||
        existingValueInProperties(emoney, lastnumber) === false
      ) {
        setFieldValue(props.name, "");
      }
    }
  };

  useEffect(() => {
    listnumbers();
  }, [emoney, epin]);

  const handleClick = (value) => {
    setSelected(value);
    setFieldValue(props.name, value);
  };

  return (
    <div>
      <label
        id={props.id}
        className={classesKym[`${props.className.labelClassName}`]}
      >
        {label}
      </label>
      <Grid container justifyContent="space-between">
        {validateNumber(numbers)
          ? Object.keys(numbers).map((key) => {
              if (
                numbers[key] !== "No aplica" &&
                numbers[key] !== undefined &&
                numbers[key] !== ""
              ) {
                return (
                  <React.Fragment key={numbers[key]}>
                    <Grid key={numbers[key]} xs={5}>
                      <ToggleButton
                        key={numbers[key]}
                        value={numbers[key]}
                        selected={selected === numbers[key]}
                        className={
                          classesKym[props.className.toggleButton] +
                          (values.active_cell_number === numbers[key]
                            ? " " + classesKym.toggleSelected
                            : "")
                        }
                        onChange={() => handleClick(numbers[key])}
                      >
                        {numbers[key]}
                      </ToggleButton>
                    </Grid>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })
          : null}
      </Grid>
    </div>
  );
}
