import { React, useState, useEffect, useRef } from "react";

const Timer = ({ props, initialText = "", endText = "" }) => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState(props.timerProps?.format);
  const [visibleTimer, setVisibleTimer] = useState(true);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const getCalculateTimer = (hours, minutes, seconds) => {
    return (
      (props.timerProps?.hours ? (hours > 9 ? hours : "0" + hours) + ":" : "") +
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds)
    );
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      const calculaTimer = getCalculateTimer(hours, minutes, seconds);

      setTimer(calculaTimer);
      validateTime(calculaTimer);
    }
  };

  const clearTimer = (e) => {
    setTimer(props.timerProps?.initialMasck);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + props.timerProps?.seconds);
    return deadline;
  };

  const validateTime = (time) => {
    if (time === props.timerProps?.format) {
      setVisibleTimer(false);
      props.setMessageOTP({
        display: "block",
        message: "Se agotó el tiempo de espera, solicita un nuevo código.",
        icon: "warning",
      });
      props.setRestartTimer(false);
    }
  };

  useEffect(() => {
    if (props.restartTimer) {
      setVisibleTimer(true);
      clearTimer(getDeadTime());
      props.setRestartTimer(false);
    }
  }, [props.restartTimer, visibleTimer]);

  return (
    <>
      {visibleTimer && (
        <p>
          {initialText} <b>{timer}</b> {endText}
        </p>
      )}
    </>
  );
};

export default Timer;
