import React from "react";
import { Field, useField } from "formik";
import themeKym from "../../utils/themes/ThemeKym";
import TextField from "@material-ui/core/TextField";

const Telephone = ({ props }) => {
  const classesKym = themeKym();
  const [field] = useField(props.name);

  const configTextfield = {
    ...field,
    label: props.label,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    minLength: props.minLength,
  };

  const mask = (e) => {
    let x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = x
      .slice(1)
      .filter((n) => n !== "")
      .join("-");

    field.onBlur(e);
    field.onChange(e);
  };

  const onlyNumbers = (e) => {
    let x = e.target.value;
    x = x.replace(/\D/g, "");

    if (x.length > 10) {
      e.target.value = x.slice(0, 10);
    } else {
      e.target.value = x;
    }
    field.onChange(e);
  };

  configTextfield.value = configTextfield.value ?? "";

  if (props.material) {
    return (
      <TextField
        variant="outlined"
        {...configTextfield}
        className={classesKym[`${props.className}`]}
        data-testid={`${props.id}-${props.className}`}
        onChange={(e) => onlyNumbers(e)}
        onBlur={(e) => mask(e)}
        inputProps={{ minLength: props.minLength, maxLength: props.maxLength }}
      />
    );
  } else {
    return (
      <Field
        {...configTextfield}
        onChange={(e) => onlyNumbers(e)}
        onBlur={(e) => mask(e)}
      />
    );
  }
};
export default Telephone;
