import React from "react";
import { Button, Grid } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const ButtonsOTP = ({
  btn,
  btnNext,
  btnBefore,
  btnResendOTP,
  dirty,
  isValid,
  messageOTP,
  pinComplete,
}) => {
  // pinComplete = true;
  // console.log("messageOtp", messageOTP)
  // messageOTP = { display: "block", message: "", icon: "" };
  const valideButton = btn.buttons[0];
  const resendButton = btn.buttons[1];
  const theme = useTheme();
  const getClasses = (btn) => {
    if (btn.styleClassName) {
      let _class = [];
      _class.push(btn.styleClassName);
      return _class.join(" ").trim();
    }

    return "merchant-primary";
  };

  const getMethodOnclick = (methodName) => {
    let method;
    switch (methodName) {
      case "next":
        method = btnNext;
        break;
      case "before":
        method = btnBefore;
        break;
      case "ResendOTP":
        method = btnResendOTP;
        break;
      default:
        method = btnBefore;
    }
    return method;
  };
  const sizesGrid = {
    buttonAppearsGridxstrue: 10,
    buttonAppearsGridDisplaytrue: { display: "none" },

    buttonAppearsGridxsfalse: 5,
    buttonAppearsGridDisplayfalse: {},
  };

  const sizesGridWithAction = {
    buttonNamefalse: valideButton.label,
    buttonActionfalse: getMethodOnclick(valideButton.name),
    buttonTypefalse: valideButton.type,

    buttonNametrue: resendButton.label,
    buttonActiontrue: getMethodOnclick(resendButton.name),
    buttonTypetrue: resendButton.type,
  };
  const _sizesGridWithAction = {
    "buttonName-error-complete": resendButton.label,
    "buttonAction-error-complete": getMethodOnclick(resendButton.name),
    "buttonType-error-complete": resendButton.type,
    "buttonBlocked-error-complete": false,

    "buttonName-NoError-complete": valideButton.label,
    "buttonAction-NoError-complete": getMethodOnclick(valideButton.name),
    "buttonType-NoError-complete": valideButton.type,
    "buttonBlocked-NoError-complete": false,

    "buttonName-NoError-NoComplete": valideButton.label,
    "buttonAction-NoError-NoComplete": getMethodOnclick(valideButton.name),
    "buttonType-NoError-NoComplete": valideButton.type,
    "buttonBlocked-NoError-NoComplete": true,

    "buttonName-error-NoComplete": resendButton.label,
    "buttonAction-error-NoComplete": getMethodOnclick(resendButton.name),
    "buttonType-error-NoComplete": resendButton.type,
    "buttonBlocked-error-NoComplete": false,
  };
  const sizeGridController = (type, xs) => {
    let key = type + xs.toString();

    return sizesGrid[key];
  };
  const sizeGridControllerWithAction = (type, error, complete, matches) => {
    let action = false;
    let key = "";
    let _error = error ? "error" : "NoError";
    let _complete = complete ? "complete" : "NoComplete";

    if (matches) {
      key = `${type}-${_error}-${_complete}`;

      return _sizesGridWithAction[key];
    } else {
      if (type == "buttonBlocked") {
        return !(messageOTP?.icon !== "warning" && pinComplete) && !matches;
      }
      key = type + action.toString();
      return sizesGridWithAction[key];
    }
  };

  let matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item xs={sizeGridController("buttonAppearsGridxs", matches)}>
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          className={getClasses(valideButton)}
          type={sizeGridControllerWithAction(
            "buttonType",
            messageOTP?.icon == "warning",
            pinComplete,
            matches
          )}
          name={sizeGridControllerWithAction(
            "buttonName",
            messageOTP?.icon == "warning",
            pinComplete,
            matches
          )}
          onClick={sizeGridControllerWithAction(
            "buttonAction",
            messageOTP?.icon == "warning",
            pinComplete,
            matches
          )}
          style={valideButton.styles}
          disabled={sizeGridControllerWithAction(
            "buttonBlocked",
            messageOTP?.icon == "warning",
            pinComplete,
            matches
          )}
        >
          {sizeGridControllerWithAction(
            "buttonName",
            messageOTP?.icon == "warning",
            pinComplete,
            matches
          )}
        </Button>
      </Grid>
      <Grid
        item
        xs={5}
        sx={sizeGridController("buttonAppearsGridDisplay", matches)}
      >
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          className={getClasses(resendButton)}
          type={resendButton.type}
          name={resendButton.name}
          onClick={getMethodOnclick(resendButton.name)}
          style={resendButton.styles}
        >
          {resendButton.label}
        </Button>
      </Grid>
    </Grid>
  );
};
export default ButtonsOTP;
