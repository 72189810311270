import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const actualWidth = window.innerWidth;

const themeKym = makeStyles((theme: Theme) => ({
  titleInfoWC: {
    fontFamily: "Roboto !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "18px !important",
    color: "#00000A !important",
    textAlign: "justify",
  },
  valueInfoWC: {
    fontFamily: "Roboto !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: "#4C5866 !important",
    textAlign: "justify",
  },
  TitleSection: {
    fontFamily: "Roboto",
    fontSize: "20px !important",
    fontWeight: "600 !important",
    lineHeight: "32px !important",
    color: "#4C5866 !important",
    [theme.breakpoints.down("xs")]: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#5F6368",
    },
  },
  AvatarForm: {
    backgroundColor: "#FFC70D !important",
    color: "#FFFFFF !important",
    [theme.breakpoints.down("xs")]: {
      width: "24px !important",
      height: "24px !important",
      background: "#FFC70D !important",
    },
  },
  sectionContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "25px !important",
    },
  },
  footerContainer: {
    fontFamily: "Roboto !important",
    fontStyle: "normal !important",
    fontWeight: "300 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: "#000000 !important",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px !important",
      lineHeight: "14px",
      textAlign: "justify",
      paddingRight: "15px",
    },
  },
  FormKym: {
    width: "100% !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      backgroundColor: "#FFFFFF",
    },
  },
  mainGridContainer: {
    height: "55vh",
  },
  gridSectionButton: {
    height: "30%",
  },
  gridSectionForm: {
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "75%",
      padding: "20px",
      paddingTop: "0px",
      width: (props) => props.width,
    },
  },
  gridForm: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px !important",
      width: (props) => props.width,
    },
  },
  Title: {
    marginTop: "80px",
    fontFamily: "Roboto !important",
    fontWeight: "600 !important",
    lineHeight: "35px !important",
    color: "#404A56 !important",
    [theme.breakpoints.down("xs")]: {
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "700 !important",
      fontSize: "20px !important",
      lineHeight: "24px !important",
      color: "#00377D !important",
      width: "100%",
    },
  },
  Subtitle: {
    fontFamily: "Roboto !important",
    fontWeight: "400 !important",
    lineHeight: "30px !important",
    color: "#404A56 !important",
    [theme.breakpoints.down("xs")]: {
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "16px !important",
      lineHeight: "24px !important",
      color: "#343C46 !important",
      width: "100%",
    },
  },
  titleWC: {
    marginTop: "80px",
    fontFamily: "Roboto !important",
    fontWeight: "600 !important",
    lineHeight: "35px !important",
    color: "#404A56 !important",
    fontSize: "38px !important",
    textAlign: "center !important",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  subtitleWC: {
    fontFamily: "Roboto !important",
    fontWeight: "400 !important",
    lineHeight: "30px !important",
    color: "#404A56 !important",
    fontSize: "18px !important",
    textAlign: "center !important",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  boxTitleContainer: {
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      paddingBottom: "16px",
      width: "100%",
    },
  },
  stackButtons: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important",
      width: (props) => props.width,
    },
  },
  stackButtonsWC: {
    "& div": {
      width: "25% !important",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
        padding: "0px 0px 0px 16px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important",
      width: (props) => props.width,
      padding: "0px 44px 0px 20px",
    },
  },
  textFieldWC: {
    background: "#FFFFFF",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "16px !important",
    },
    "& .MuiOutlinedInput-input": {
      border: "none !important",
    },
    "& .MuiInputBase-input": {
      border: "none !important",
      [theme.breakpoints.down("xs")]: {
        fontFamily: "Roboto !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "16px !important",
        lineHeight: "16px !important",
        color: "#343C46 !important",
      },
    },
    "& .MuiFormLabel-root": {
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "18px !important",
    },
    "& .MuiInputLabel-formControl": {
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "12px !important",
      color: "#4C5866 !important",
    },
    "& .MuiInputLabel-shrink": {
      position: "absolute",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "18px !important",
      color: "#363853",
    },
    border: "0px solid #BFC7D0 !important",
    borderRadius: "16px !important",
  },
  Container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  Card: {
    width: "99%",
    background: "#FFF",
    marginBottom: "10px",
    borderRadius: "16px",
    boxShadow: "0px 4px 12px rgba(194, 209, 217, 0.46)",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      borderRadius: "16px",
    },
  },
  CardYesNo: {
    width: "12%",
    height: "70px",
    background: "#FFF",
    marginBottom: "10px",
    borderRadius: "16px",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "17%",
      height: "52px",
      borderRadius: "16px",
    },
  },
  RadioButtonLbl: {
    fontFamily: "Roboto !important",
    fontStyle: "normal !important",
    width: "100% !important",
    padding: "30px !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    flexWrap: "wrap !important",
    marginBottom: "0px !important",
    [theme.breakpoints.down("xs")]: {
      padding: "15px 15px 0px 15px !important",
    },
  },
  RadioButtonTitle: {
    fontFamily: "Roboto !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: "#4C5866 !important",
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      flexWrap: "wrap !important",
    },
  },
  Input: {
    marginRight: "10px",
  },
  RadioSubtitle: {
    display: "none !important",
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      justifyContent: "flex-start !important",
      flexWrap: "wrap !important",
      marginBottom: "0px !important",
      fontFamily: "Roboto !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      fontSize: "10px !important",
      lineHeight: "12px !important",
      color: "#708195 !important",
      padding: "0px 15px 15px 55px !important",
    },
  },
  mainGridContainerWC: {
    height: "95vh !important",
  },
  classButtons: {
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  uploadFileContainerWC: {
    width: "99% !important",
  },
  CardContainer: {
    maxWidth: "100%",
  },
  UploadFileCardContainerV2: {
    display: "inline-block",
    width: "100%",
    border: "2px dashed rgba(128, 138, 161, 0.5) !important",
    borderRadius: "25px !important",
  },
  CardContainerCard: {
    padding: "30px 0px 30px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "15px 0px 10px 0px",
    },
  },
  CardContainerButtonUpload: {
    marginBottom: "0px !important",
    "& .MuiButtonBase-root": {
      display: "inline-flex",
      backgroundColor: "#363853",
      "& svg": {
        width: "30px !important",
        height: "30px !important",
      },
      [theme.breakpoints.up("sm")]: {
        display: "none !important",
      },
    },
    "& a": {
      color: "#FFFFFF",
      backgroundColor: "#363853",
      padding: "12px",
      textDecoration: "none",
      borderRadius: "16px",
      fontWeight: 500,
      fontSize: "16px",
      letterSpacing: "0.25px",
      [theme.breakpoints.down("xs")]: {
        display: "none !important",
      },
    },
  },
  CardContainerButtonReplace: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
    lineHeight: "24px !important",
    "& a": {
      textDecoration: "none",
      "& svg": {
        width: "20px !important",
        height: "20px !important",
      },
      "& span": {
        color: "#404A56 !important",
        backgroundColor: "#FFF !important",
        padding: "0px 0px 0px 10px",
        [theme.breakpoints.down("xs")]: {
          display: "none !important",
        },
      },
    },
  },
  CardContainerText: {
    "& span": {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "34px",
      letterSpacing: "0.247px",
      color: "#404A56",
      float: "left",
      width: "100%",
      textAlign: "initial",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        lineHeight: "22px",
      },
    },
    "& .web": {
      [theme.breakpoints.down("xs")]: {
        display: "none !important",
      },
    },
    "& .mobile": {
      [theme.breakpoints.up("sm")]: {
        display: "none !important",
      },
    },
  },
  CardContainerIcon: {
    "& svg": {
      width: "50px !important",
      height: "50px !important",
      [theme.breakpoints.down("xs")]: {
        width: "30px !important",
        height: "30px !important",
      },
    },
  },
  CardContainerSubText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px !important",
    lineHeight: "18px",
    letterSpacing: "0.247px",
    color: "#404A56",
    float: "left",
    textAlign: "initial",
    "& span": {
      [theme.breakpoints.down("xs")]: {
        display: "none !important",
      },
    },
  },
  SelectaLbelMaterialUI: {
    fontFamily: "Roboto !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "12px !important",
    letterSpacing: "0.247px !important",
    color: "#4C5866 !important",
  },
  SelectMaterialUI: {
    border: "0px solid #BFC7D0 !important",
    borderRadius: "16px !important",
    textAlign: "start !important",
    height: "44px !important",
  },
  MenuIteamMaterialUI: {
    width: "100%",
    textAlign: "start !important",
  },
  TextFieldOTP: {
    background: "#FFFFFF",
    border: "1.6px solid #CFD5DC",
    borderRadius: "8px",
    "& .MuiInputBase-input": {
      height: "95px",
      width: "75px",
      textAlign: "center",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "26px",
      lineHeight: "22px",
      // border: "none !important",
    },
  },
  TextTimerOTP: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.247px",
    color: "#404A56",
    marginTop: "34px",
  },
  TitleOPT: {
    fonrFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "44px",
    letterSpacing: "0.247px",
    color: "#404A56",
    float: "left",
    textAlign: "center",
    display: "contents",
  },
  SubtitleOTP: {
    fonrFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "44px",
    letterSpacing: "0.247px",
    color: "#404A56",
    float: "left",
    textAlign: "center",
    display: "contents",
  },
  ContentError: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.247px",
    color: "#4C5866",

    background: "#FAEDEA !important",
    border: "1px solid #FAEDEA",
    borderRadius: "8px",
    height: "56px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  OtpSuccesTitle: {
    fonrFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "43px",
    lineHeight: "44px",
    textAlign: "center",
    color: "#404A56",
  },
  InformativeViewContainer: {
    paddingBottom: "300px",
  },
  toggleLabel: {
    textAlign: "left !important",
    lineHeight: "24px !important",
    fontSize: "16px !important",
    paddingBottom: "20px !important",
  },
  toggleButton: {
    color: "#363853 !important",
    fontSize: "14px !important",
    border: "1px solid #363853 !important",
    borderRadius: "20px !important",
    background: "#FFFFFF !important",
    padding: "10px 25px !important",
    fontWeight: "bold !important",
    width: "-webkit-fill-available !important",
    marginBottom: "10px !important",
  },
  toggleSelected: {
    background: "#363853 !important",
    color: "#FFFFFF !important",
    fontWeight: "100 !important",
  },
}));

export default themeKym;
