import React from "react";
import { Button as _Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Button = ({ btn, btnNext, btnBefore, btnResendOTP, dirty, isValid }) => {
  const disabled =
    !(dirty && isValid) && (btn.styleClassName ?? "").includes("primary");

  const getClasses = () => {
    if (btn.styleClassName) {
      let _class = [];
      _class.push(btn.styleClassName);
      if (disabled) _class.push("merchant-disabel");

      return _class.join(" ").trim();
    }

    return "merchant-primary";
  };

  const getMethodOnlcik = (methodName) => {
    let method;
    switch (methodName) {
      case "next":
        method = btnNext;
        break;
      case "before":
        method = btnBefore;
        break;
      case "ResendOTP":
        method = btnResendOTP;
        break;
      default:
        method = btnBefore;
    }
    return method;
  };

  return (
    <React.Fragment>
      {btn.name === "before" && btn.view ? (
        <_Button
          color="secondary"
          variant="contained"
          className={getClasses()}
          type={btn.type}
          name={btn.name}
          onClick={getMethodOnlcik(btn.name)}
          // style={{width: "45% !important"}}
          disabled={disabled}
        >
          <ArrowBackIosIcon />
        </_Button>
      ) : (
        <_Button
          color="secondary"
          variant="contained"
          fullWidth
          className={getClasses()}
          type={btn.type}
          name={btn.name}
          onClick={getMethodOnlcik(btn.name)}
          style={btn.styles}
          disabled={disabled}
        >
          {btn.label}
        </_Button>
      )}
    </React.Fragment>
  );
};
export default Button;
