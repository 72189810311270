import React from "react";
import { Field, useField } from "formik";
import Checkbox from "@mui/material/Checkbox";

const CheckBox = ({ props }) => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = (e) => {
    field.onChange(e);
    setChecked(e.target.checked);
  };
  const [field] = useField(props.name);
  const configTextField = {
    placeholder: props.placeholder,
    type: "checkbox",
    name: field.name,
    value: field.name,
    id: props.id,
  };

  return (
    <table>
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", padding: "0 10px 0 10px" }}>
            {props.material ? (
              <Checkbox
                {...configTextField}
                checked={checked}
                onChange={(e) => handleChange(e)}
              />
            ) : (
              <Field {...configTextField} />
            )}
          </td>
          <td align="justify">{configTextField.placeholder}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default CheckBox;
