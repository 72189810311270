import "../App.css";
import FormV2 from "./formV2";
import Form from "./form";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosClient from "../actions/axiosClient";
import { Typography, Box, Grid } from "@mui/material";
import { format } from "date-fns";
import themeKym from "../utils/themes/ThemeKym";
import axiosClientWc from "../actions/axiosClientMwc";
import GenericBackdrop from "../utils/GenericBackdrop";
import { getCountryCode } from "../utils/country-code-store";
import { getTimeForCountryCode } from "../utils/country-time-data";
import moment from "moment-timezone";

const FormContainer = () => {
  const [data, setData] = useState(null);
  const [dataForms, setDataForms] = useState(null);
  const [userData, setUserData] = useState(null);
  const [step, changeStep] = useState(0);
  const [module, setModule] = useState(false);
  const [isBack, setIsBack] = useState(true);
  const [msisdn, setMsisdn] = useState(0);
  const [idpdv, setIdpdv] = useState(0);
  const [selectItems, setSelectItems] = useState({});
  const [cedula, setCedula] = useState("");
  const [formData, setFormData] = useState([]);
  const [formDataStruct, setFormDataStruct] = useState({});
  const [fileLoaded, setFileLoaded] = useState(false);
  const [sectionType, setSectionType] = useState(false);
  const [OTP, setOTP] = useState(null);
  const [messageOTP, setMessageOTP] = useState({
    display: "none",
    message: "",
    icon: "",
  });
  const [timerProps, setTimerProps] = useState({});
  const [OTPComplete, setOTPComplete] = useState("false");
  const [restartTimer, setRestartTimer] = useState(false);
  const [epinCell, setEpinCell] = useState();
  const [eMoneyCell, setEmoneyCell] = useState();
  const [numberpin, setnumberpin] = useState("");
  const [pinComplete, setPinComplete] = useState(false);
  const search = useLocation().search;
  const parentUrl = document.location.ancestorOrigins[0];
  const formId = new URLSearchParams(search).get("form_id");
  const country = new URLSearchParams(search).get("country");
  const email = new URLSearchParams(search).get("email");
  const userEmail = new URLSearchParams(search).get("user_email");
  const action = new URLSearchParams(search).get("action");
  const idUser = new URLSearchParams(search).get("idUser");
  const remedy = new URLSearchParams(search).get("remedy");
  const classesKym = themeKym();
  const WorkingCapital = "Working-Capital";
  const notApply = "No aplica";
  const [loader, setLoader] = useState(false);
  const countryCode = getCountryCode(country);
  const today = getTimeForCountryCode(country);

  const findFieldsValues = (fields, values) => {
    fields.forEach(({ props }) => {
      if (props.type === "column") {
        findFieldsValues(props.fields, values);
      } else {
        props.defaultValue = values[props.name];
        props.value = values[props.name];
        if (props.name === "telephone") {
          setMsisdn(values[props.name]);
        }
        if (props.name === "id_pdv") {
          setIdpdv(values[props.name]);
        }
      }
    });
  };

  const formatValue = (type, value) => {
    if (type == "date") {
      return format(new Date(value), "dd/MM/yyyy");
    }
    return value;
  };

  const typeFormatValue = (type, name, value) => {
    if (type == "checkbox") {
      return value.length > 0;
    } else if (type.includes("uploadfilecard")) {
      return {
        type: "document",
        status: "pending",
        key: `${msisdn != 0 ? msisdn : idpdv}/${name}.${value.name
          .split(".")
          .pop()}`,
      };
    }

    return formatValue(type, value);
  };

  const getValueForSection = (sec, values) => {
    let values_ = {};
    sec.fields.forEach((field) => {
      let name = field.props.name;
      if (field.props.type == "column") {
        for (let i in field.props.fields) {
          name = field.props.fields[i].props.name;
          if (field.props.fields[i].props.type == "checkbox") {
            values_[name] = values[name].length > 0;
          } else {
            values_[name] = values[name];
          }
        }
      } else {
        values_[name] = typeFormatValue(
          field.props.type,
          field.props.name,
          values[name]
        );
      }
    });
    return values_;
  };

  const visibleSection = (sec, values) => {
    if (sec.hasOwnProperty("radio")) {
      if (values[sec.radio.name] == sec.radio.value) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  };

  const insertUser = async (data_, module_user = false) => {
    if (module_user == WorkingCapital) {
      data_["module"] = module;
    }
    return axiosClient
      .post(`${process.env.REACT_APP_DOMAIN}/user`, { ...data_ })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  const updateUser = async (data_user, user_email) => {
    return axiosClient
      .put(`${process.env.REACT_APP_DOMAIN}/user?email=${user_email}`, {
        ...data_user,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  const getForm = async () => {
    return axiosClient
      .get(
        `${process.env.REACT_APP_DOMAIN}/form?formId=${formId}&country=${country}&email=${email}&action=${action}`
      )
      .then((response) => {
        return response;
      });
  };

  const getPhoneNumber = async (dataValues) => {
    const celInfo = dataValues.Negocio["owner-information"];
    let epinCellNumber =
      celInfo["epin_cell_number"] === notApply
        ? ""
        : celInfo["epin_cell_number"];
    let emoneyCellNumber =
      celInfo["emoney_cell_number"] === notApply
        ? ""
        : celInfo["emoney_cell_number"];

    setEpinCell(epinCellNumber);
    setEmoneyCell(emoneyCellNumber);
    return [epinCellNumber, emoneyCellNumber];
  };

  const validateEpinEmoney = async (epinCellNumber, emoneyCellNumber) => {
    return epinCellNumber.length > 0 || emoneyCellNumber.length > 0;
  };

  const normalizeFormData = (formData_normalize) => {
    let business_name = "";
    let legal_representative_name = "";
    let legal_representative_type = "";
    let dataContract;
    try {
      business_name =
        formData_normalize["Negocio"]["business-information"]["business_name"];
      legal_representative_name =
        formData_normalize["Negocio"]["business-information"][
          "legal_representative_name"
        ];
      legal_representative_type =
        formData_normalize["Negocio"]["business-information"][
          "legal_representative_type"
        ];
    } catch {}
    dataContract = {
      Negocio: {
        business_type: {
          type_of_company:
            formData_normalize["Negocio"]["business_type"]["type_of_company"],
        },
        "owner-information": {
          name: formData_normalize["Negocio"]["owner-information"]["name"],
          lastname:
            formData_normalize["Negocio"]["owner-information"]["lastname"],
        },
        "facturation-information": {
          id_number: cedula,
          ruc: formData_normalize["Negocio"]["facturation-information"]["ruc"],
          id_pdv: idpdv,
          contributing_indicator_field:
            formData_normalize["Negocio"]["facturation-information"][
              "contributing_indicator_field"
            ],
        },
        "contact-information": {
          email: userEmail,
          epin_code: "",
          epin_cell_number:
            formData_normalize["Negocio"]["owner-information"][
              "epin_cell_number"
            ],
          emoney_code: "",
          emoney_cell_number:
            formData_normalize["Negocio"]["owner-information"][
              "emoney_cell_number"
            ],
        },
        "business-information": {
          business_name: business_name,
          legal_representative_name: legal_representative_name,
          legal_representative_type: legal_representative_type,
        },
        documents: formData_normalize["Documentos"],
        terminosycondiciones: true,
      },
      email: userEmail,
      country: formData_normalize["country"],
      formId: formData_normalize["formId"],
      currentStep: formData_normalize["currentStep"],
    };
    return dataContract;
  };

  const userRequestValidation = async (dataValues) => {
    const response = await insertUser(dataValues, module);
    if (response.status !== 200) {
      response.data === "The user already exists"
        ? alert("Usuario ya registrado")
        : alert("favor de intentar mas tarde");
    } else {
      window.parent.postMessage(
        { message: "CREATE", data: dataValues, code: 200 },
        parentUrl
      );
    }
  };

  const updateRemedyDocs = async (dataValues) => {
    let documents = dataValues.Documentos.documents;
    let user = userData.userData;
    const remedyDocuments = userData.remedyDocs;
    remedyDocuments.map((doc) => {
      user.Negocio.documents[doc] = documents[doc];
    });
    return user;
  };

  const redirectToWorkingCapitalFront = async (user) => {
    window.parent.postMessage(
      {
        message: "UPDATED_REMEDY_DOCS",
        data: user,
        code: 200,
        isDeleted: true,
      },
      parentUrl
    );
  };

  const userRequest = async (dataValues) => {
    if (module === WorkingCapital) {
      if (remedy !== "true") {
        dataValues = normalizeFormData(dataValues);
        dataValues.createDate = new Date().toISOString();
        await userRequestValidation(dataValues);
      } else {
        const user = await updateRemedyDocs(dataValues);
        await updateUser(user, user.email);
        await redirectToWorkingCapitalFront(user);
      }
    } else {
      if (!data.email && action == null) {
        dataValues.email = data.email = email;
        return insertUser(dataValues);
      } else {
        if (action != null){
          dataValues.email = email;
        }
      }
      return updateUser(dataValues, email);
    }
  };

  const recordExists = async (value, type) => {
    let param = "";
    if (type === "email") {
      param = `email=${value}`;
    } else if (type === "idpdv") {
      param = `id_pdv=${value}`;
    }
    const response = await userGet(param);
    if (response.status === 200) {
      return response.data["msg"] !== "record not foud";
    }
    return false;
  };

  const userGet = async (param) => {
    return axiosClient
      .get(`${process.env.REACT_APP_DOMAIN}/user?${param}`)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  const buildSectionRemedy = async (dataForm) => {
    if (userData) {
      let section = dataForm.forms[step].section.filter(
        (x) => x.sectionType === sectionType
      );

      dataForm.forms[step].section = section;
      const remedyDocuments = [];
      section.map((key) => {
        if (key.sectionType === sectionType) {
          key.fields.map((properties) => {
            const status =
              userData.userData.Negocio.documents[properties.props.name].status;
            if (
              userData.remedyDocs.includes(properties.props.name) &&
              status === "rejected"
            ) {
              properties.props["status"] = status;
              properties.props["reasonRejected"] =
                userData.userData.Negocio.documents[
                  properties.props.name
                ].reasonRejected;
              properties.props["otherReason"] =
                userData.userData.Negocio.documents[
                  properties.props.name
                ].otherReason;
              remedyDocuments.push(properties);
            }
          });
          key.fields = remedyDocuments;
        }
      });
    }
    return dataForm;
  };

  const buildSections = (values) => {
    const section = data.forms[step].section;
    const footer = data.forms[step].footer
      ? data.forms[step].footer.fields
      : [];
    let sectionData = {};
    section.map(async (sec) => {
      let pass = visibleSection(sec, values);
      if (pass) {
        sectionData[sec.sectionName] = getValueForSection(sec, values);
        findFieldsValues(sec.fields, values);
      }
      if (sec.form === "Modal") {
        sectionData[sec.sectionName] = sec.table.data;
      }
    });
    footer.map(async (e) => {
      if (e.props.type === "checkbox") {
        sectionData[e.props.name] = values[e.props.name].length > 0;
      } else {
        sectionData[e.props.name] = values[e.props.name];
      }
    });
    return sectionData;
  };

  const filterSectionForTypeOfCompany = async (values) => {
    if (
      module === WorkingCapital &&
      data.steps > 0 &&
      values["type_of_company"]
    ) {
      setSectionType(values["type_of_company"]);
    }
  };

  const filterPhoneNumbers = async (typeFilter, data_pdv) => {
    return data_pdv
      .filter((x) => x.cell_type === typeFilter)
      .map((item) => {
        return {
          id: item.id,
          defaulValue: item.cell_number,
          label: item.cell_number,
          type: item.cell_type,
          document_number: item.document_number,
        };
      });
  };

  const validate_id_pdv = async (id_pdv) => {
    setLoader(true);
    if (await recordExists(id_pdv, "idpdv")) {
      showErrorMessage(
        "id_pdv",
        "El Punto Tigo ya tiene una solicitud en revisión."
      );
      setLoader(false);
      return false;
    }
    const response = await get_id_pdv(id_pdv);
    if (response.status === 200) {
      let epin = await filterPhoneNumbers("epin", response.data);
      let emoney = await filterPhoneNumbers("emoney", response.data);
      let document_number;
      if (epin.length > 0 || emoney.length > 0) {
        document_number =
          epin.length > 0 ? epin[0].document_number : emoney[0].document_number;
      }
      setSelectItems({
        epin: epin,
        emoney: emoney,
      });
      setCedula(document_number);
      setLoader(false);
      return true;
    } else {
      showErrorMessage("id_pdv", response.data);
      setLoader(false);
      return false;
    }
  };

  const get_id_pdv = async (id_pdv) => {
    return axiosClientWc
      .get(`${process.env.REACT_APP_DOMAIN_MWC}/idpdv/${id_pdv}`)
      .then((response) => {
        return response;
      })
      .catch((_err) => {
        return _err.response;
      });
  };

  const validateWC = async (dataValues, stageName, values) => {
    let aux;
    if (module === WorkingCapital) {
      switch (stageName) {
        case "Negocio":
          deleteErrorMessage("id_pdv");
          if (!(await validate_id_pdv(values.id_pdv))) {
            return false;
          }
          values["type_of_company_form"] = "Persona " + values.type_of_company;
          setFormDataStruct(dataValues);
          break;
        case "Formulario":
          aux = formDataStruct;
          values["nombreyapellido"] = values.name + " " + values.lastname;
          aux["country"] = country;
          for (const sectionData in dataValues["Formulario"]) {
            aux["Negocio"][sectionData] = dataValues["Formulario"][sectionData];
          }
          const [epinCellN, emoneyCellN] = await getPhoneNumber(aux);
          setFormDataStruct(aux);
          deleteErrorMessage("select_epin");
          deleteErrorMessage("select_emoney");
          if (!(await validateEpinEmoney(epinCellN, emoneyCellN))) {
            showErrorMessage(
              "select_epin",
              "Favor de seleccionar al menos un número",
              false
            );
            showErrorMessage(
              "select_emoney",
              "favor de seleccionar al menos un número",
              false
            );
            return false;
          }
          setnumberpin(aux.Negocio["owner-information"].active_cell_number);
          OTPComplete !== true &&
            firstLoginOTP(aux.Negocio["owner-information"].active_cell_number);
          break;
        case "Documentos":
          aux = formDataStruct;
          aux[stageName] = dataValues[stageName]["documents"];
          setFormDataStruct(aux);
          break;
        case "Confirmacion":
          values["type_of_company_form"] = "Persona " + values.type_of_company;
          setFormDataStruct(dataValues);
          break;
      }
    }
    return true;
  };

  const deleteErrorMessage = (id) => {
    let id_div = "div_tmp" + id;
    let div2 = document.getElementById(id_div);
    if (div2) div2.remove();
  };

  const showErrorMessage = (id, msg, isInput = true) => {
    let txt = document.getElementById(id);
    if (txt) {
      if (isInput) {
        txt.parentNode.className =
          "MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl";
      }
      let id_div = "div_tmp" + id;

      let div = document.createElement("div");
      div.id = id_div;
      div.textContent = msg;
      div.className = "error";
      txt.parentNode.parentNode.after(div);
    }
  };

  const updateDefaultValuesEpinEmoney = async (values, stageName) => {
    if (stageName === "Formulario")
      if (values.emoney_cell_number === "") {
        values.emoney_cell_number = notApply;
      }
    if (values.epin_cell_number === "") {
      values.epin_cell_number = notApply;
    }
  };

  const getOtpCode = async (msisdn_otp) => {
    return axiosClientWc
      .get(
        `${process.env.REACT_APP_DOMAIN_MWC}/otp/${idpdv}?msisdn=${msisdn_otp}`
      )
      .then((response) => {
        return response;
      })
      .catch((_err) => {
        return _err.response;
      });
  };

  const getValidationOtp = async (otpCode) => {
    return axiosClientWc
      .get(
        `${process.env.REACT_APP_DOMAIN_MWC}/validation/otp/${idpdv}?otp_code=${otpCode}`
      )
      .then((response) => {
        return response;
      })
      .catch((_err) => {
        return _err.response;
      });
  };

  const updateTimer = async (response) => {
    if (response.status === 200) {
      const expireDate = moment(response.data.expire_date);
      const todayFormated = moment(today.toString().slice(0, -6));
      const seconds = expireDate.diff(todayFormated, "seconds");
      setTimerProps({
        format: "00:00",
        seconds: seconds > 300 ? 300 : seconds,
        initialMasck: seconds > 300 ? "05:00" : "00:00",
        hours: false,
      });
      setRestartTimer(true);
    }
  };

  const loginOTP = async () => {
    if (
      module === WorkingCapital &&
      data.forms[step]?.stageName === "validate-otp"
    ) {
      setMessageOTP({ display: "none", message: "", status: false, icon: "" });
      const response = await getOtpCode(numberpin);
      await updateTimer(response);
    }
  };

  const firstLoginOTP = async (numberpin) => {
    const response = await getOtpCode(numberpin);
    await updateTimer(response);
  };

  const validateOTP = async () => {
    setMessageOTP({ display: "none", message: "", status: false, icon: "" });
    if (
      module === WorkingCapital &&
      data.forms[step + 1]?.stageName === "Documentos"
    ) {
      if (OTP) {
        const responseValidation = await getValidationOtp(OTP);

        if (responseValidation.status !== 200) {
          setMessageOTP({
            display: "block",
            message: "El código es incorrecto",
            icon: "warning",
          });
          return false;
        }
        setMessageOTP({
          display: "block",
          message: "El código fue validado con éxito",
          icon: "check",
        });
        setOTPComplete(true);
        return responseValidation;
      } else {
        setMessageOTP({
          display: "block",
          message: "Todas las casillas deben estar llenas",
          icon: "warning",
        });
        return false;
      }
    }
    return true;
  };

  const resendOTP = async () => {
    await loginOTP();
  };

  const nextStep = async (values) => {
    if (step == parseInt(data.steps) - 1) {
      window.parent.postMessage(
        { message: "LOADING", data: {}, code: 200 },
        parentUrl
      );
    }

    const stageName = data.forms[step].stageName;
    await updateDefaultValuesEpinEmoney(values, stageName);
    await filterSectionForTypeOfCompany(values);
    let auxData = formData;
    let response;
    let dataValues = {};
    const checkpoint =
      data.forms[step].checkpoint == null ? true : data.forms[step].checkpoint;
    let sectionData = buildSections(values);
    dataValues[stageName] = sectionData;
    dataValues.email = (data && data.email) || userEmail;
    dataValues.formId = data.formId;
    dataValues.currentStep = step + 1;
    data.type_of_company = values.type_of_company;
    if (step + 1 > data.currentStep) data.currentStep = step;

    if (remedy !== "true" && !(await validateWC(dataValues, stageName, values)))
      return "";

    if (!(await validateOTP())) {
      return;
    }
    if (checkpoint) {
      if (module === WorkingCapital && step > 0) {
        dataValues = formDataStruct;
      }
      response = await userRequest(dataValues);
    }

    if (step < parseInt(data.steps) - 1) {
      auxData[step] = values;
      setFormData(auxData);
      data["forms"][step]["status"] = "Validate";
      if (
        module === WorkingCapital &&
        OTPComplete === true &&
        data.forms[step].stageName === "Formulario"
      ) {
        changeStep(step + 2);
      } else {
        changeStep(step + 1);
      }
      setIsBack(false);
    } else {
      if (module !== WorkingCapital && action == null) {
        window.parent.postMessage(
          { message: "CREATE", data: response.data.data, code: 200 },
          parentUrl
        );
        window.parent.postMessage(
          { message: "SUCCESS", data: data, code: 200 },
          parentUrl
        );
      } else if (action != null) {
        window.parent.postMessage(
          { message: "UPDATED", data: data, code: 200 },
          parentUrl
        );
      }
    }
  };

  const skipOtp = () => {
    setLastStepValues(step - 2, formData[step - 2]);
    setIsBack(true);
    changeStep(step - 2);
  };

  const beforeStep = () => {
    if (
      module === WorkingCapital &&
      OTPComplete === true &&
      data.forms[step].stageName === "Documentos"
    ) {
      skipOtp();
    } else {
      if (data.forms[step - 1].stageName === "Negocio") {
        setSectionType(false);
      }
      if (data.forms[step - 1].stageName === "Documentos") {
        setFileLoaded(true);
      }
      setLastStepValues(step - 1, formData[step - 1]);

      setIsBack(true);
      changeStep(step - 1);
    }
  };

  const setLastStepValues = (step_values, values) => {
    const section = data.forms[step].section;
    section.map(async (sec) => {
      findFieldsValues(sec.fields, values);
    });
  };

  const deleteTable = (index) => {
    data.forms[step].section[0].table.data.splice(index, 1);
    setIsBack(false);
    setData({ ...data });
  };

  const saveTable = (item) => {
    let id = item.id;
    let saveData = data;
    if (id != undefined) {
      delete item.id;
      saveData.forms[step].section[0].table.data[id] = item;
    } else {
      saveData.forms[step].section[0].table.data.push(item);
    }
    setData(saveData);
  };

  const onChangeRadio = async (value) => {
    if (data !== null && data.forms[0].hasOwnProperty("typePerson")) {
      let dataTemp = data;
      dataTemp.forms = dataForms?.filter(
        (x) => x.typePerson === value || x.typePerson === "initial"
      );
      dataTemp.steps = dataTemp.forms.length;
      setData(dataTemp);
    }
  };

  const searchRemedyDcocuments = async (responseUser) => {
    // get documents for remedy
    const typeCompany = responseUser.data.Negocio.business_type.type_of_company;
    const userDocuments = responseUser.data.Negocio.documents;
    const remedyDocs = Object.keys(userDocuments).filter(
      (document) => userDocuments[document].status === "rejected"
    );
    setSectionType(typeCompany);
    setUserData({ remedyDocs: remedyDocs, userData: responseUser.data });
  };

  useEffect(async () => {
    let isMounted = true;
    const controller = new AbortController();
    if (
      isMounted &&
      data !== null &&
      data?.forms[0].hasOwnProperty("typePerson") &&
      action == null
    ) {
      onChangeRadio(data?.forms[0].section[0].fields[0].props.value);
    } else {
      if (data == null && remedy !== "true") {
        const res = await getForm();
        setData(res.data);
        setDataForms(res.data.forms);
        setModule(res.data.proyectName);
        if (res.data.currentStep) {
          if (res.data.currentStep + "" === res.data.steps + "") {
            window.parent.postMessage(
              { message: "SUCCESS", data: {}, code: 200 },
              parentUrl
            );
          } else {
            changeStep(res.data.currentStep);
          }
        }
      }
      if (userData) {
        const res = await getForm();
        const formData_remedy = await buildSectionRemedy(res.data);
        setData(formData_remedy);
        setDataForms(formData_remedy.forms);
        setModule(formData_remedy.proyectName);
      }
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [dataForms, userData]);

  useEffect(() => {
    if (remedy === "true" && idUser) {
      userGet(`id_pdv=${idUser}`).then((responseUser) => {
        setIdpdv(idUser);
        searchRemedyDcocuments(responseUser);
      });
    }
  }, []);

  if (data === null) {
    return (
      <div className="loading">
        <h1>Loading</h1>
        <div className="loadingText"></div>
      </div>
    );
  }

  const countryCheck = () => {
    if (country != "hn" && formId == "F0009")
      return (
        <FormV2
          form={data.forms[step]}
          dataForms={data.forms}
          steppers={data.steppers ?? false}
          btnNext={nextStep}
          btnBefore={beforeStep}
          isBack={isBack}
          deleteTable={deleteTable}
          saveTable={saveTable}
          country={data.country}
          onChangeRadio={(e) => onChangeRadio(e)}
          msisdn={msisdn}
        />
      );
    else
      return (
        <>
          <GenericBackdrop open={loader} />
          <Grid container>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={12} md={10}>
              <Box mt={3} sx={{ height: "100%" }}>
                <Box ml={3} className={classesKym.boxTitleContainer}>
                  <Typography
                    align="left"
                    className={
                      classesKym[`${data.forms[step].classTitle}`] +
                      " " +
                      classesKym.Title
                    }
                    sx={{
                      fontSize: {
                        md: 42,
                        sm: 25,
                      },
                    }}
                    variant="h4"
                    gutterBottom
                    component="div"
                  >
                    {data.forms[step].title}
                  </Typography>
                  <Typography
                    align="left"
                    variant="h5"
                    className={
                      classesKym[`${data.forms[step].classSubtitle}`] +
                      " " +
                      classesKym.Subtitle
                    }
                    sx={{
                      fontSize: {
                        md: 16,
                        sm: 12,
                      },
                    }}
                    gutterBottom
                    component="div"
                    mt={3}
                  >
                    {data.forms[step].subtitle}
                  </Typography>
                </Box>
                <Form
                  countryCode={countryCode}
                  telephoneOTP={[eMoneyCell, epinCell]}
                  dataStructure={formDataStruct}
                  form={data.forms[step]}
                  btnNext={nextStep}
                  btnBefore={beforeStep}
                  sectionType={sectionType}
                  idpdv={idpdv}
                  temporalData={formData}
                  fileLoaded={fileLoaded}
                  selectItems={selectItems}
                  userData={userData}
                  setOTP={setOTP}
                  btnResendOTP={resendOTP}
                  messageOTP={messageOTP}
                  setMessageOTP={setMessageOTP}
                  setRestartTimer={setRestartTimer}
                  restartTimer={restartTimer}
                  pinComplete={pinComplete}
                  setPinComplete={setPinComplete}
                  timerProps={timerProps}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      );
  };

  return <div className="App">{countryCheck()}</div>;
};

export default FormContainer;
