import React from "react";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import themeKym from "../../utils/themes/ThemeKym";

const TextFieldMaterial = ({ props }) => {
  const classesKym = themeKym();
  const [field] = useField(props.name);

  const configTextField = {
    ...field,
    label: props.label,
    style: props.style,
    name: props.name,
    id: props.id,
    type: props.type,
    title: props.title,
    readOnly: props.readOnly,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    minLength: props.minLength,
  };
  configTextField.value = configTextField.value ?? "";

  return (
    <TextField
      className={classesKym[`${props.className}`]}
      data-testid={`${props.id}-${props.className}`}
      variant="outlined"
      {...configTextField}
      inputProps={{ minLength: props.minLength, maxLength: props.maxLength }}
    />
  );
};

export default TextFieldMaterial;
